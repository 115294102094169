.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.basic-singlehead {
  width: 320px;
  max-width: 320px;
  height: 65px;
}

.basic-singleheadsmall {
  width: 310px;
  max-width: 310px;
  height: 65px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 0px solid #41A4C3;
    -webkit-text-fill-color: #A0AEC0;
    -webkit-box-shadow: 0 0 0px 1000px #292e33 inset;
    transition: background-color 5000s ease-in-out 0s;
}


#inputID::placeholder {
  color: #575757;
  font-family: 'Roboto';
  opacity: 1;
}


.react-calendar {
  width: 300px;
  max-width: 100%;
  background: #292e33;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border-radius: 4;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  color:white
}

.react-calendar button:enabled:hover {
  cursor: pointer;
  border-radius: 10px;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
  color:white
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: transparent;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
  color:white;
  font-family: 'Roboto';
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
  font-family: 'Roboto';
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
  color:white;
  font-family: 'Roboto';
}

.react-calendar__tile:disabled {
  background-color: transparent;
  color: #ababab;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: transparent;
}

.react-calendar__tile--now {
  background: #8455F6;
  height:40px;
  width:40px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: transparent;
}

.react-calendar__tile--hasActive {
  background: transparent;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: transparent;
}

.react-calendar__tile--active {
  background: transparent;
  color: transparent;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: transparent;
  height:40px;
  width:40px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: transparent;
}
